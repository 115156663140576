<template>
  <main>
    <section class="login-form">
      <div class="login-form-inner">
        <img :src="thdLogo" class="thd-logo" width="70" alt="thd logo" />
        <h2 class="header-2">Sign Up</h2>
        <SimpleInput
          v-model="email"
          label="Email Address"
          :error="errors.email"
          error-text="This field required"
          placeholder-text="@thehomedepot.com"
          placeholder-align="right"
        />
        <SimpleInput
          v-model="password"
          label="Password"
          :error="errors.password"
          error-text="This field required"
          password
        />
        <SimpleInput
          v-model="confirmPassword"
          label="Confirm Password"
          :error="errors.confirmPassword"
          error-text="Passwords do not match"
          password
        />
        <hr class="divider" />
        <SimpleButton label="Submit" full-width @click="signIn" />
        <p class="sign-up-text">
          Already have an account?
          <router-link to="/login" class="sign-up-text-inner"
            >Login.</router-link
          >
        </p>
      </div>
    </section>
    <SloganLetsBuild />
  </main>
</template>

<script setup>
import { reactive, ref, watch } from "vue";
import SimpleInput from "@/components/ui/SimpleInput";
import thdLogo from "../assets/images/THD_Logo 1.png";
import AuthAPI from "@/api/AuthAPI";
import UserAPI from "@/api/UserAPI";
import SimpleButton from "@/components/ui/SimpleButton";
import SloganLetsBuild from "@/components/SloganLetsBuild";
const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const errors = reactive({
  email: false,
  password: false,
  confirmPassword: false,
});

const signIn = async () => {
  if (!email.value) errors.email = true;
  if (!password.value) errors.password = true;
  if (password.value !== confirmPassword.value) errors.confirmPassword = true;
  if (!Object.values(errors).every((i) => !i)) {
    return;
  }
  await UserAPI.createUser({
    email: email.value,
    password: password.value,
    access_type: "user",
  })("Will be signed in with:", email.value, password.value);
};

watch([email, password, confirmPassword], () => {
  errors.email = false;
  errors.password = false;
  errors.confirmPassword = false;
});
</script>

<style scoped>
main {
  display: flex;
  min-height: 100vh;
}

.login-form {
  display: flex;
  justify-content: center;
  width: 48%;
}

.login-form-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  height: 100%;
}

.thd-logo {
  margin-top: 20px;
  margin-bottom: 70px;
}

.header-2 {
  margin-top: 0;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 48px;
  color: black;
}

.divider {
  width: 100%;
  height: 2px;
  margin: 50px 0 30px;
  border: 0;
  background: #e5e5e5;
}

.sign-up-text {
  margin-top: 20px;
  text-align: center;
}

.sign-up-text-inner {
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  text-decoration: none;
  color: #ff8217;
}
</style>
